/* ----------------------- */
/* All CSS */
/* ----------------------- */
body {
    height: 100vh;
    background-color: #fff;
    font-family: "ヒラギノ丸ゴ Pro W4", "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "heisei-maru-gothic-std", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro" !important;
}
html {
  height: 100vh;
}
h2{
    font-size: 1.35em !important;
    font-weight: bold !important;
    color: #FF385C;
    padding: 0 0 0.7em 0 !important;
}
p {
    font-size: 15px;
    text-align: left;
    line-height: 1.9em;
}
textarea{
    resize: none;
}
strong {
    font-weight: bold !important;
}
button, input, textarea, select{
    outline: 0;
}
button {
    border: none;
}

@media screen and (max-width:600px){
  p {
    line-height: 1.7em;
     }
}

/* ----------------------- */
/* spacer */
/* ----------------------- */
.spacer {
 margin-top:1em;
}
.top-spacer {
 margin-top: 5em;
}

/* ----------------------- */
/* common */
/* ----------------------- */
.common__contents{
 display: flex;
 justify-content: center;
 text-align: center;
 padding: 3em 0;
}
.common__sub-contents{
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 7em 0;
   }
.common__size{
    width: 100%;
    max-width: 1100px;
 padding:0 1em;
}
.common__page{
   padding: 6em 0;
}

.common__page__items{
  padding:2em 0 5em 0;
}

.common__center{
  text-align: center;
}

/* ----------------------- */
/* back color */
/* ----------------------- */
.back-gray{
    background-color: #f6f6f6;
}

/* ----------------------- */
/* icon */
/* ----------------------- */
.icon {
    color: #fff;
    font-size: 2em;
}
.bar-icon {
    color: #ffffff;
    font-size: 2em;
    cursor: pointer;
}
.close-icon {
    cursor: pointer;
    color: #FF385C;
    font-size: 2em;
    padding-left: 0.5em;
}

.link-icon {
  padding-right: 1em;
}

@media screen and (max-width:600px){
    /*600px以上で適用する内容*/
    .common__size{
        width: 550px; 
        padding:0 1em;
       }
    .mb-style {
        padding-top: 1em;
    }   
    .mb-Main-style{
        width: 100%;;
    }
  }


  .nav-menu{
    padding: 0 1em;
    background-color: #fff;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top:0;
    left: -100%;
    transition: 850ms;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 20%);
    z-index: 9999;
  }

/* ----------------------- */
/* Menu CSS */
/* ----------------------- */
  .nav-menu.active {
    left: 0;
    transition: 350ms;
 }
 .nav-text {
    cursor: pointer;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 1em;
    list-style: none;
    height: 60px;
 }
  .nav-text a {
    text-decoration: none;
    color: #FF385C;
    font-size: 18px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    /* padding: 0 16px; */
    transition: all ease-out .3s;
 }
.nav-menu-items {
    width: 100%;
 }

 .contents-top{
    padding:10em 0 3em 0;
 }


/* table */
table{
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

table tr{
  border-bottom: solid 1px #eee;
  /* cursor: pointer; */
}

/* table tr:hover{
  background-color: #d4f0fd;
} */

table th,table td{
  text-align: center;
  width: 25%;
  padding: 15px 0;
}

table td.icon{
  background-size: 35px;
  background-position: left 5px center;
  background-repeat: no-repeat;
  padding-left: 30px;
}
/* 
table td.icon.bird{
  background-image: url(icon-bird.png)
}

table td.icon.whale{
  background-image: url(icon-whale.png)
}

table td.icon.crab{
  background-image: url(icon-crab.png)
} */

