.slider {
  position: relative;
  padding-top: 4.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider__image{
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size:5rem;
  color: #FF385C;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size:5rem;
  color: #FF385C;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);

}