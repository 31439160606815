/* ----------------------- */
/* All CSS */
/* ----------------------- */
body {
    height: 100vh;
    background-color: #fff;
    font-family: "ヒラギノ丸ゴ Pro W4", "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "heisei-maru-gothic-std", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro" !important;
}
html {
  height: 100vh;
}
h2{
    font-size: 1.35em !important;
    font-weight: bold !important;
    color: #FF385C;
    padding: 0 0 0.7em 0 !important;
}
p {
    font-size: 15px;
    text-align: left;
    line-height: 1.9em;
}
textarea{
    resize: none;
}
strong {
    font-weight: bold !important;
}
button, input, textarea, select{
    outline: 0;
}
button {
    border: none;
}

@media screen and (max-width:600px){
  p {
    line-height: 1.7em;
     }
}

/* ----------------------- */
/* spacer */
/* ----------------------- */
.spacer {
 margin-top:1em;
}
.top-spacer {
 margin-top: 5em;
}

/* ----------------------- */
/* common */
/* ----------------------- */
.common__contents{
 display: -webkit-flex;
 display: flex;
 -webkit-justify-content: center;
         justify-content: center;
 text-align: center;
 padding: 3em 0;
}
.common__sub-contents{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    padding: 7em 0;
   }
.common__size{
    width: 100%;
    max-width: 1100px;
 padding:0 1em;
}
.common__page{
   padding: 6em 0;
}

.common__page__items{
  padding:2em 0 5em 0;
}

.common__center{
  text-align: center;
}

/* ----------------------- */
/* back color */
/* ----------------------- */
.back-gray{
    background-color: #f6f6f6;
}

/* ----------------------- */
/* icon */
/* ----------------------- */
.icon {
    color: #fff;
    font-size: 2em;
}
.bar-icon {
    color: #ffffff;
    font-size: 2em;
    cursor: pointer;
}
.close-icon {
    cursor: pointer;
    color: #FF385C;
    font-size: 2em;
    padding-left: 0.5em;
}

.link-icon {
  padding-right: 1em;
}

@media screen and (max-width:600px){
    /*600px以上で適用する内容*/
    .common__size{
        width: 550px; 
        padding:0 1em;
       }
    .mb-style {
        padding-top: 1em;
    }   
    .mb-Main-style{
        width: 100%;;
    }
  }


  .nav-menu{
    padding: 0 1em;
    background-color: #fff;
    width: 250px;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    position: fixed;
    top:0;
    left: -100%;
    transition: 850ms;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 20%);
    z-index: 9999;
  }

/* ----------------------- */
/* Menu CSS */
/* ----------------------- */
  .nav-menu.active {
    left: 0;
    transition: 350ms;
 }
 .nav-text {
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
    margin-left: 1em;
    list-style: none;
    height: 60px;
 }
  .nav-text a {
    text-decoration: none;
    color: #FF385C;
    font-size: 18px;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    /* padding: 0 16px; */
    transition: all ease-out .3s;
 }
.nav-menu-items {
    width: 100%;
 }

 .contents-top{
    padding:10em 0 3em 0;
 }


/* table */
table{
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

table tr{
  border-bottom: solid 1px #eee;
  /* cursor: pointer; */
}

/* table tr:hover{
  background-color: #d4f0fd;
} */

table th,table td{
  text-align: center;
  width: 25%;
  padding: 15px 0;
}

table td.icon{
  background-size: 35px;
  background-position: left 5px center;
  background-repeat: no-repeat;
  padding-left: 30px;
}
/* 
table td.icon.bird{
  background-image: url(icon-bird.png)
}

table td.icon.whale{
  background-image: url(icon-whale.png)
}

table td.icon.crab{
  background-image: url(icon-crab.png)
} */


/**
 * YUI 3.5.0 - reset.css (http://developer.yahoo.com/yui/3/cssreset/)
 * http://cssreset.com
 * Copyright 2012 Yahoo! Inc. All rights reserved.
 * http://yuilibrary.com/license/
 */
/*
	TODO will need to remove settings on HTML since we can't namespace it.
	TODO with the prefix, should I group by selector or property for weight savings?
*/
html{
	color:#000;
	background:#FFF;
}
/*
	TODO remove settings on BODY since we can't namespace it.
*/
/*
	TODO test putting a class on HEAD.
		- Fails on FF.
*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
	margin:0;
	padding:0;
}
table {
	border-collapse:collapse;
	border-spacing:0;
}
fieldset,
img {
	border:0;
}
/*
	TODO think about hanlding inheritence differently, maybe letting IE6 fail a bit...
*/
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
	font-style:normal;
	font-weight:normal;
}

ol,
ul {
	list-style:none;
}

caption,
th {
	text-align:left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-size:100%;
	font-weight:normal;
}
q:before,
q:after {
	content:'';
}
abbr,
acronym {
	border:0;
	font-feature-settings:normal;
	font-variant:normal;
}
/* to preserve line-height and selector appearance */
sup {
	vertical-align:text-top;
}
sub {
	vertical-align:text-bottom;
}
input,
textarea,
select {
	font-family:inherit;
	font-size:inherit;
	font-weight:inherit;
}
/*to enable resizing for IE*/
input,
textarea,
select {
	*font-size:100%;
}
/*because legend doesn't inherit in IE */
legend {
	color:#000;
}
/* YUI CSS Detection Stamp */
#yui3-css-stamp.cssreset { display: none; }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.slider {
  position: relative;
  padding-top: 4.5em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.slider__image{
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size:5rem;
  color: #FF385C;
  z-index: 10;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size:5rem;
  color: #FF385C;
  z-index: 10;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  -webkit-transform: scale(1.08);
          transform: scale(1.08);

}
